<template>
	<div class="wrapper title-image">
		<section class="section" style="padding-top: 250px;">
			<div class="h1">A smarter view into the ocean</div>
			<img class="coral-down" src="@/assets/images/V_coral_down.png">
			<div>
				<p class="h4" style="max-width: 500px; margin: auto;">
					Underwater monitoring for
					infrastructure and conservation
				</p>
				<p class="body" style="padding-top: 40px; max-width: 500px; margin: auto;">
					Fathom Ocean develops secure and resilient underwater observatories 
					to protect our oceans and infrastructure. Our AI-powered solutions 
					monitor the underwater landscape with real-time analysis of live-stream 
					video, audio and sensor data. All delivered through a secure app.
				</p>
			</div>
			<hr class="coral">
		</section>

		<section class="section">
			<div class="h2">The Fathom ecosystem</div>
			<div style="position: relative">
				<img class="jelly" src="@/assets/images/Jelly.png">
			</div>
			<!-- Periscope -->
			<img src="@/assets/images/periscope.png">
			<div>
				<p class="h4">
					periscope
				</p>
				<p class="body" style="padding-top: 40px;">
					All of our systems are powered by Periscope, <br>
					our AI-powered monitoring app
				</p>
				<div style="padding-top: 40px">
					<p class="body2">AI</p>
					<hr class="short">
					<p class="body2">Continuous monitoring</p>
					<hr class="short">
					<p class="body2">Relevant data collection</p>
					<hr class="short">
					<p class="body2">Motion detection</p>
					<hr class="short">
					<p class="body2">Species and object ID</p>
					<hr class="short">
					<p class="body2">Smart alerts for targeted action</p>
				</div>
			</div>

			<!-- Octopus -->
			<div style="padding-top: 150px"></div>
			<img src="@/assets/images/octopus.png">
			<div>
				<p class="h4">
					octopus
				</p>
				<p class="body" style="padding-top: 40px; padding-bottom: 40px">
					Our flagship, self-cleaning 360° underwater camera
				</p>
				<div class="octo-wrapper">
					<div class="octo-image">
						<img src="@/assets/images/Octopus_Wireframe.png" width="130">
					</div>
					<div class="octo-desc">
						<p class="body2-left">remote control</p>
						<hr class="short-left">
						<p class="body2-left">360° pan, tilt and zoom</p>
						<hr class="short-left">
						<p class="body2-left">1080p livestream video and audio</p>
						<hr class="short-left">
						<p class="body2-left">ptz vision system</p>
					</div>
				</div>
			</div>

			<!-- Octopus -->
			<div style="padding-top: 150px"></div>
			<img src="@/assets/images/Orca.png">
			<div>
				<p class="h4">
					orca
				</p>
				<p class="body" style="padding-top: 40px; padding-bottom: 40px">
					Versatile, self-cleaning vision system powered by an NVIDIA GPU
				</p>
				<div class="octo-wrapper">
					<div class="octo-image" style="padding-top: 30px;">
						<img src="@/assets/images/Orca_Wireframe.png" width="250">
					</div>
					<div class="octo-desc">
						<p class="body2-left">on-board AI</p>
						<hr class="short-left">
						<p class="body2-left">real-time, local data collection</p>
						<hr class="short-left">
						<p class="body2-left">multi-device deployment</p>
						<hr class="short-left">
						<p class="body2-left">fully scalable</p>
					</div>
				</div>
			</div>
			<!-- <hr class="coral"> -->
		</section>

		<section style="background-color: white; padding-top: 40px; padding-bottom: 40px;">
			<img class="coral-down" src="@/assets/images/V_coral_down.png">
			<p class="h4-dark" style="max-width: 400px; margin: auto;">
				What's beneath the surface: technical precision for underwater durability
			</p>
			<div style="margin-top: 40px;">
				<img src="@/assets/images/fathom_orca.png" width=400 />
			</div>
		</section>

		<!-- 20 years in the making -->
		<section class="section">
			<div class="h2" style="margin-top: 40px;">20 years in the making</div>
			<div style="margin-top: 40px;">
				<img src="@/assets/images/fathom_octopus_glow.png" width=600 />
			</div>
			<p class="body" style="text-align: center; max-width: 300px; margin: auto;">
				Our self-cleaning underwater monitoring systems are the proven choice for researchers, educational organizations and businesses. Every day, millions of viewers enjoy live-stream action from our observatories in oceans around the world.
			</p>
		</section>

		<!-- Photo -->
		<section>
			<img class="photo" src="@/assets/images/photo_octo1.png" />
		</section>

		<section>
			<div class="carousel-wrapper">
				<img class="earth" src="@/assets/images/Wireframe_Earth.png"/>
				<b-carousel class="videos">
					<b-carousel-item>
							<video class="video" src="@/assets/videos/hawaii_small.mp4" type="video/mp4" autoplay loop></video>
					</b-carousel-item>
					<b-carousel-item>
						<video class="video" src="@/assets/videos/deerfield_small.mp4" type="video/mp4" autoplay loop></video>
					</b-carousel-item>
					<b-carousel-item>
						<video class="video" src="@/assets/videos/fryingpan_small.mp4" type="video/mp4" autoplay loop></video>
					</b-carousel-item>
					<b-carousel-item>
						<video class="video" src="@/assets/videos/bonaire_small.mp4" type="video/mp4" autoplay loop></video>
					</b-carousel-item>
					<b-carousel-item>
						<video class="video" src="@/assets/videos/manatee_small.mp4" type="video/mp4" autoplay loop></video>
					</b-carousel-item>
					<b-carousel-item>
						<video class="video" src="@/assets/videos/coralcity_small.mp4" type="video/mp4" autoplay loop></video>
					</b-carousel-item>
					<b-carousel-item>
						<video class="video" src="@/assets/videos/vivathekeys_small.mp4" type="video/mp4" autoplay loop></video>
					</b-carousel-item>
				</b-carousel>
			</div>
		</section>

		<!-- smart solutions -->
		<section>
			<div style="margin-top: 150px;"></div>
			<img class="coral-down" src="@/assets/images/V_coral_down.png">
			<div class="h2">Smart solutions for energy and beyond</div>
			<!-- <div class="smart-wrapper">
				<div class="smart-left">
					<img src="@/assets/images/Periscope_app_mock_industrial_monitor 2.png">
				</div>
				<div class="smart-right">
					<img src="@/assets/images/seagrass.png">
					<p class="body" style="text-align: left">
						Our custom solutions for energy and infrastructure collect <br>
						the precise data your business demands. With a proven track <br>
						record spanning decades, our rugged underwater systems can <br>
						be deployed in deep-sea conditions for a variety of applications.
					</p>
				</div>
			</div> -->
			<div class="grid-wrapper">
				<div class="grid-box">
					<div>
						<div style="text-align: right;">
							<img src="@/assets/images/Periscope_app_mock_industrial_monitor 2.png" width="300">
						</div>
					</div>
				</div>
				<div class="grid-box">
					<div>
						<div>
							<img src="@/assets/images/seagrass.png" width="200">
						</div>
						<p class="body" style="text-align: left; max-width: 300px;">
							Our custom solutions for energy and infrastructure collect 
						the precise data your business demands. With a proven track 
						record spanning decades, our rugged underwater systems can 
						be deployed in deep-sea conditions for a variety of applications.
						</p>
					</div>
				</div>
			</div>
		</section>


		<section>
			<div class="grid-wrapper" style="max-width: 600px; margin: auto; margin-top: 100px;">
				<div class="grid-box">
					<div>
						<div style="height: 200px; position: relative">
							<img src="@/assets/images/fish.png" style="position: absolute; left: 0; bottom: 10px;">
						</div>
						<p class="h3" style="text-align: left;">Powerful AI</p>
						<p class="body" style="text-align: left;">
							Automatically detect and report species counts, trash and debris, unauthorized humans, environmental conditions and more using leading-edge AI like.  
						</p>
					</div>
				</div>
				<div class="grid-box">
					<div>
						<div style="height: 200px; position: relative">
							<img src="@/assets/images/graph.png" style="position: absolute; left: 0; bottom: 10px;">
						</div>
						<p class="h3" style="text-align: left;">Long-term monitoring</p>
						<p class="body" style="text-align: left;">
							Collect long-term data on specific wildlife activity or points of interest using AI to parse through video or audio footage at scale.
						</p>
					</div>
				</div>
				<div class="grid-box">
					<div>
						<div style="height: 200px; position: relative">
							<img src="@/assets/images/selfcleaning.png" style="position: absolute; left: 0; bottom: 10px;">
						</div>
						<p class="h3" style="text-align: left;">Self-cleaning technology</p>
						<p class="body" style="text-align: left;">
							Time-tested and field-proven, our CleanSweep™ technology simplifies maintenance and prevents image degradation without the use of harsh chemicals. 
						</p>
					</div>
				</div>
				<div class="grid-box">
					<div>
						<div style="height: 200px; position: relative">
							<img src="@/assets/images/simple.png" style="position: absolute; left: 0; bottom: 20px;">
						</div>
						<p class="h3" style="text-align: left;">Simple to set up and use</p>
						<p class="body" style="text-align: left;">
							Our systems are designed for plug-and-play operation through the Periscope app. Once in place, enter the device ID, and our AI-enabled cameras will register themselves.
						</p>
					</div>
				</div>
			</div>
		</section>

		<!-- Photo -->
		<section>
			<img class="photo" src="@/assets/images/photo_dirty_octo.jpeg" style="margin-top: 100px;"/>
		</section>

		<!-- Contact -->
		<section>
			<div style="margin-top: 150px;"></div>
			<p class="h4" style="margin-bottom: 25px;">get in touch</p>
			<hr class="coral-short">
			<div style="margin-top: 50px;"></div>
			<p class="h2-sand" style="margin-bottom: 25px;">See Fathom in Action</p>
			<p class="body" style="max-width: 400px; margin: auto;">
				Learn how Fathom delivers modular, cost-effective underwater monitoring solutions to businesses and organizations around the world. 
			</p>
			<b-button style="margin-top: 50px;" @click="$router.push('/contact')">get in touch</b-button>
			<!-- <hr class="coral"> -->
		</section>

		<!-- Trusted -->
		<section>
			<div style="margin-top: 150px;"></div>
			<p class="h2-sand" style="margin-bottom: 25px;">Trusted expertise</p>
			<p class="body" style="max-width: 400px; margin: auto;">
				For two decades, we've provided cost-effective underwater monitoring solutions for businesses, researchers and educational organizations worldwide.
			</p>
			<img src="@/assets/images/partners.png" style="margin-top: 80px;">
		</section>


		<section>
			<div style="margin-top: 150px;"></div>
			<img src="@/assets/images/fathom_family.png" width=600>
		</section>

	</div>
</template>

<script>

export default {
	name: 'Main',
	data() {
		return {
		}
	},
	methods: {
	},
}
</script>
<style lang="scss">
@import "@/styles/_variables.scss";
@import url('https://fonts.googleapis.com/css?family=Inconsolata');

.wrapper {
	margin-top: -160px;
}

.title-image {
	background: url(../assets/images/Surface_image.png) no-repeat;
	background-size: 100vw;
}

.photo {
	width: 100vw;
}

hr.coral {
	border: none;
	height: 1px;
	color: $coral;
	background-color: $coral;
	width: 60%;
	margin: auto;
	margin-top: 100px;
}
hr.coral-short {
	border: none;
	height: 1px;
	color: $coral;
	background-color: $coral;
	width: 140px;
	margin: auto;
}
hr.coral-dotted {
	border: none;
	height: 1px;
	color: $coral;
	background-color: $coral;
	width: 40%;
	margin-top: 10px;
}
hr.short {
	border: none;
	height: 1px;
	color: white;
	background-color: white;
	width: 40px;
	margin: auto;
	margin-top: 8px;
	margin-bottom: 8px;
}

hr.short-left {
	border: none;
	height: 1px;
	color: white;
	background-color: white;
	width: 40px;
	margin-top: 8px;
	margin-bottom: 8px;
}
.coral-down {
	margin-bottom: 75px;
}
.jelly {
	position: absolute;
	right: 0px;
	top: -200px;
}

.octo-wrapper {
	margin: auto; 
	text-align: center;
}
.octo-image {
	text-align: right;
	float: left;
	width: 50%;
	padding-right: 20px;
}
.octo-desc {
  float: left;
  width: 50%;
  padding-left: 20px;
}
.carousel-wrapper {
	margin: auto; 
	text-align: center;
	position: relative;
	margin-top: 80px;
	// margin-left: 80px;
}

.videos {
	max-width: 450px;
	margin: auto;
	position: absolute;
	padding-top: 80px;
}
.video {
	border-radius: 5px;
	overflow: hidden;
}
.earth {
	max-width: 400px;
	position: absolute; 
	left: 50%;
	margin-left: -200px;
	// top: -100px;
}
/* Clear floats after the columns */
.octo-wrapper:after {
  content: "";
  display: table;
  clear: both;
}
.carousel-wrapper:after {
  content: "";
  display: table;
  clear: both;
}
.smart-wrapper {
	margin: auto; 
	text-align: center;
	position: relative;
	margin-left: 80px;
}
.smart-left {
	text-align: left;
	float: left;
	width: 50%;
	padding-right: 20px;
}
.smart-right {
	text-align: left;
	float: left;
	width: 50%;
	padding-left: 20px;
}
.smart-wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.grid-wrapper {
  display: flex;
  flex-wrap: wrap;
//   margin: 100px;
  text-align: left;
}

.grid-box {
	flex: 25%;
	padding: 20px;
}
@media screen and (max-width: 1200px) {
  .grid-box {
    flex: 50%;
  }
}
@media screen and (max-width: 600px) {
  .grid-wrapper {
    flex-direction: column;
  }
}

</style>
