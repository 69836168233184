import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { mapActions } from 'vuex'

import Main from '@/components/Main.vue';
import Industry from '@/components/Industry.vue';
import Conservation from '@/components/Conservation.vue';
import About from '@/components/About.vue';
import Contact from '@/components/Contact.vue';
import Terms from '@/components/Terms.vue';
import Warranty from '@/components/Warranty.vue';

Vue.use(VueRouter)

const routes = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: Home,
    // },
    {
      path: '/',
      component: Main,
    },
    {
      path: '/industry',
      component: Industry,
    },
    {
      path: '/conservation',
      component: Conservation,
    },
    {
      path: '/about',
      component: About,
    },
    {
      path: '/contact',
      component: Contact,
    },
    {
      path: '/terms',
      component: Terms,
    },
    {
      path: '/warranty',
      component: Warranty,
    },
    {
      // path: "*",
      path: "/:catchAll(.*)",
      redirect: '/' 
    },
]

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { x: 0, y: 0 }
  },
})

export default router
