<template>
  <div id="app">
    <Home/>
  </div>
</template>

<script>
import Home from '@/views/Home.vue'

export default {
	name: 'App',
	components: {
		Home,
  },
}
</script>

<style lang="scss">
@import "./styles/_variables.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
html {
  background-color: $background !important;
  scroll-behavior: smooth;
}
body {
    overflow-x: hidden;
}
</style>


