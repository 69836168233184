<template>
    <div style="margin-top: 80px;">
        <section>
            <div class="h2">Revolutionary industrial monitoring</div>
            <div>
				<p class="h4">
					Real-time underwater visibility
				</p>
                <p class="body" style="padding-top: 40px; max-width: 350px; margin: auto;">
					Smart underwater cameras with high-powered lights streamline monitoring and compliance in any scenario. 
				</p>
            </div>
            <div style="margin-top: 80px; margin-bottom: -80px;">
                <p class="h4-blue">the fathom system</p>
                <img style="margin-top: 40px;" src="@/assets/images/V_coral_down.png">
            </div>
            <img src="@/assets/images/industry_schematic.png">
    
        </section>

        <section class="fathom-fade">
            <p class="h4" style="padding-top: 100px;">
                Many cameras, one monitoring platform
            </p>
            <div class="fathom-box" style="max-width: 400px; margin: auto; margin-top: 20px;">
                <p class="body">
                    Plug-and-play cameras seamlessly connect to the Periscope platform for secure real-time viewing and administration.  
                </p>
            </div>
            <div>
                <div style="margin-top: 100px;">
                    <img src="@/assets/images/security.png" width="50" />
                </div>
                <p class="h4" style="padding-top: 20px;">
                    security first
                </p>
                <div class="fathom-box-coral" style="max-width: 400px; margin: auto; margin-top: 20px;">
                    <p class="body">
                        Fathom’s hybrid cloud approach makes it easy to view live-video over your private LAN or remotely over a secure VPN. 
                    </p>
                </div>
            </div>
        </section>


        <section style="margin-top: 300px;">
            <div style="text-align: left; background-color: black; padding-top: 50px; padding-bottom: 50px;">
                <p class="h4" style="padding-top: 20px;">
                    intelligent video scrubbing saves valuable time
                </p>
                <img class="computer-lines" src="@/assets/images/computer_lines.png">
                <div class="fathom-box" style="max-width: 400px; margin: auto; margin-top: 20px;">
                    <p class="body">
                        Periscope’s integrated AI combs through hours of video footage to quickly surface important events.
                    </p>
                </div>
            </div>
        </section>

        <!-- Photo -->
        <section>
            <!-- <img class="photo-side" src="@/assets/images/photo_angler.png"> -->
			<img class="photo" src="@/assets/images/photo_octo_upsidedown.png">
		</section>

        <section style="background-color: white; padding: 50px; margin-top: -10px;">
            <div>
                <p class="h2-dark">
                    Fathom Systems
                </p>
                <div class="smart-wrapper" style="max-width: 800px; margin: auto;">
                    <div class="smart-left">
                        <img src="@/assets/images/computer_ipad.png"  width="300">
                    </div>
                    <div class="smart-right">
                        <div style="text-align: center; margin-bottom: 20px;">
                            <img src="@/assets/images/periscope.png" >
                            <p class="h4">periscope</p>
                        </div>
                          
                        <p class="body-dark" style="text-align: left">
                            Our AI-enabled app connects you to the action using a hybrid cloud approach. View camera footage over your LAN outside the public internet. Or view it remotely over a secure VPN and share your live-stream with others.
                        </p>
                    </div>
                </div>
                <div class="smart-wrapper" style="max-width: 800px; margin: auto; margin-top: 100px;">
                    <div class="smart-left">
                        <img src="@/assets/images/fathom_octopus.png" width="600">
                    </div>
                    <div class="smart-right">
                        <div style="text-align: center; margin-bottom: 20px;">
                            <img src="@/assets/images/octopus.png" >
                            <p class="h4">octopus</p>
                        </div>
                          
                        <p class="body-dark" style="text-align: left">
                            Our flagship camera that set the standard for underwater video streaming. Like all of our cameras, Octopus offers plug-and-play installation and easy viewing for scientists, the public or a specific audience.
                        </p>
                    </div>
                </div>
                <div class="smart-wrapper" style="max-width: 800px; margin: auto; margin-top: 100px;">
                    <div class="smart-left" style="text-align: center;">
                        <img src="@/assets/images/fathom_orca.png" width="300">
                    </div>
                    <div class="smart-right">
                        <div style="text-align: center; margin-bottom: 20px;">
                            <img src="@/assets/images/Orca.png" >
                            <p class="h4">orca</p>
                        </div>
                        <p class="body-dark" style="text-align: left">
                            Powered by an NVIDIA GPU, a world leader in AI, our Orca system delivers cost-effective, long-term monitoring of underwater assets from a fixed viewpoint. Real-time artificial intelligence provides relevant footage on demand. 
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Contact -->
		<section>
			<div style="margin-top: 150px;"></div>
			<p class="h4" style="margin-bottom: 25px;">get in touch</p>
			<hr class="coral-short">
			<div style="margin-top: 50px;"></div>
			<p class="h2-sand" style="margin-bottom: 25px;">See Fathom in Action</p>
			<p class="body" style="max-width: 400px; margin: auto;">
				Learn how Fathom delivers modular, cost-effective underwater monitoring solutions to businesses and organizations around the world. 
			</p>
			<b-button style="margin-top: 50px;" @click="$router.push('/contact')">get in touch</b-button>
			<!-- <hr class="coral"> -->
		</section>

		<section>
			<div style="margin-top: 150px;"></div>
			<img src="@/assets/images/fathom_family.png" width=600>
		</section>

    </div>
</template>

<script>
export default {
    name: 'Industry',
}
</script>

<style lang="scss">
@import "@/styles/_variables.scss";
@import url('https://fonts.googleapis.com/css?family=Inconsolata');

.fathom-fade {
    background-image: linear-gradient(black, $background);
    height: 300px;
    margin-top: -10px;
}

.photo-side {
	width: 50vw;
    height: 550px;
}
.lines {
    position: absolute;
    right: 0px;
    height: 500px;
    margin-top: 40px;
    margin-right: 20px;
}
.lights {
    margin-top: 100px;
}
.computer-lines {
    width: 800px;
    margin-top: 100px;
}
.plot {
    margin-top: 100px;
}

</style>