import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'
Vue.use(Buefy)

import VideoBackground from 'vue-responsive-video-background-player'
Vue.component('video-background', VideoBackground);

Vue.config.productionTip = false

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBQuf7Yq3UFQXxmZYBaFQkvQkbmQE8XXok",
  authDomain: "coral-vision-282617.firebaseapp.com",
  projectId: "coral-vision-282617",
  storageBucket: "coral-vision-282617.appspot.com",
  messagingSenderId: "419397085366",
  appId: "1:419397085366:web:88e5ae94dfab4f377cde17",
  measurementId: "G-J4JSL93BKQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

router.replace('/')
