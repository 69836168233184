<template>
    <div style="margin-top: 80px;">
        <section>
            <div class="h2">Smart solutions for conservation</div>
            <div>
				<p class="h4">
					Real-time underwater visibility
				</p>
                <p class="body" style="padding-top: 40px;">
					Smart underwater cameras with high-powered lights <br>streamline monitoring and compliance in any scenario. 
				</p>
            </div>
            <div style="margin-top: 80px; margin-bottom: -10px;">
                <!-- <p class="h4-blue">the fathom system</p> -->
                <!-- <img style="margin-top: 40px;" src="@/assets/images/V_coral_down.png"> -->
            </div>
            <img style="margin-left: -120px;" src="@/assets/images/conservation_schematic.png">
    
        </section>

        <section style="margin-top: 100px;">
            <p class="h2">Our conservation partnerships</p>
            <div>
                <div class="wireframe-earth">
                    <img src="@/assets/images/wireframe_globe.png" width="300"/>
                    <img class="loc-tahiti" src="@/assets/images/loc.png" width="30"/>
                    <p class="loc-text-tahiti">MO'OREA, FRENCH POLYNESIA</p>
                </div>
                <div style="margin-top: -60px">
                    <p class="h5" >the coral gardeners, mo'orea</p>
                    <p class="body" style="padding-top: 40px; max-width: 600px; margin: auto;">
                        Fathom is a proud partner of the Coral Gardeners, an international collective of scientists, engineers, creators, and advocates determined to save the reef by revolutionizing ocean conservation and generating collaborative action around the world.
                    </p>
                    <p class="h4" style="margin-top: 30px;">
                        <a href="https://labs.coralgardeners.org" target="_blank">visit their garden in mo'orea, french polynesia</a></p>
                    <img class="coral-down" style="margin-top: 30px;" src="@/assets/images/V_coral_down.png">
                    <img class="banner" src="@/assets/images/cg_banner.png" style="margin-top: -80px;"/>
                </div>
            </div>

            <div style="margin-top: 200px">
                <div class="wireframe-earth">
                    <img src="@/assets/images/wireframe_globe.png" width="300"/>
                    <img class="loc-hawaii" src="@/assets/images/loc.png" width="30"/>
                    <p class="loc-text-hawaii">hilo, hawaii</p>
                </div>
                <div style="margin-top: -60px">
                    <p class="h5" >mega lab hawaii reef observatory</p>
                    <p class="body" style="padding-top: 40px; max-width: 600px; margin: auto;">
                        The multi-scale environmental graphical analysis (MEGA) lab is a global consortium of scientists, athletes, and artists who use science as a way to improve our planet. The overarching goal of the MEGA lab is to create innovative solutions that protect our oceans, providing to the communities that need them the most.
                    </p>
                    <p class="h4" style="margin-top: 30px;">
                        <a href="https://www.themegalab.org" target="_blank">dive into the reef in hilo, hawaii</a></p>
                    <img class="coral-down" style="margin-top: 30px;" src="@/assets/images/V_coral_down.png">
                    <img class="banner" src="@/assets/images/hawaii_banner.png" style="margin-top: -80px;"/>
                </div>
            </div>
        </section>

        <section style="margin-top: 100px;">
            <p class="h2">Field-tested for conservation</p>
            <div style="text-align: left;">
                <img src="@/assets/images/turtle.png" width="400"/>
                <div class="fathom-box" style="margin-top: -100px;">
                    <p class="body">
                        On-board AI learns what to look for based on your criteria.
                    </p>
                </div>
            </div>
            <div style="text-align: center; padding-right: 150px; margin-top: 100px;">
                <img src="@/assets/images/octopus_wire_cleaning.png" width="300"/>
            </div>
            <div class="fathom-box" style="margin-top: 0px; max-width: 500px;">
                <p class="body">
                    Our patented, self-cleaning technology eliminates the need
                    for frequent cleanings, simplifying maintenance and reducing
                    disruption to long-term monitoring and data collection.
                </p>
            </div>
            <div style="text-align: center; margin-top: 100px;">
                <img src="@/assets/images/PlusIcon.png" width="70"/>
            </div>
            <div class="fathom-box" style="margin-top: 0px; max-width: 500px;">
                <p class="body">
                    Pair with additional sensors for sound and temperature
                    to broaden the scope of underwater monitoring.
                </p>
            </div>
            <div style="position: relative; margin-top: 100px; margin-bottom: 500px;">
                <img class="humpback" src="@/assets/images/humpback.png" width="600"/>
                <img class="acoustic" src="@/assets/images/acoustic_lines.png" /> 
            </div>
            <div style="margin-top: -80px;">
                <img src="@/assets/images/soundwave.png" /> 
                <div class="fathom-box" style="margin-top: 0px;">
                    <p class="body">
                        Measure sounds and marine life with the hydrophone.
                    </p>
                </div>
            </div>
            <div style="margin-top: 100px;">
                <img src="@/assets/images/temp_graph.png" width="600"/> 
            </div>
            <div style="margin-top: -0px;">
                <img src="@/assets/images/temp.png" /> 
                <div class="fathom-box" style="margin-top: 0px;">
                    <p class="body">
                        Track water temperature to monitor coral health.
                    </p>
                </div>
            </div>
        </section>

        <!-- Photo -->
		<section>
			<img class="photo" src="@/assets/images/photo_octo_hero.jpg" style="margin-top: 100px;"/>
		</section>

        <section style="background-color: white; padding: 50px; margin-top: -10px;">
            <div>
                <p class="h2-dark">
                    Fathom Systems
                </p>
                <div class="smart-wrapper" style="max-width: 800px; margin: auto;">
                    <div class="smart-left">
                        <img src="@/assets/images/computer_ipad.png"  width="300">
                    </div>
                    <div class="smart-right">
                        <div style="text-align: center; margin-bottom: 20px;">
                            <img src="@/assets/images/periscope.png" >
                            <p class="h4">periscope</p>
                        </div>
                          
                        <p class="body-dark" style="text-align: left">
                            Our AI-enabled app connects you to the action using a hybrid cloud approach. View camera footage over your LAN outside the public internet. Or view it remotely over a secure VPN and share your live-stream with others.
                        </p>
                    </div>
                </div>
                <div class="smart-wrapper" style="max-width: 800px; margin: auto; margin-top: 100px;">
                    <div class="smart-left">
                        <img src="@/assets/images/fathom_octopus.png" width="600">
                    </div>
                    <div class="smart-right">
                        <div style="text-align: center; margin-bottom: 20px;">
                            <img src="@/assets/images/octopus.png" >
                            <p class="h4">octopus</p>
                        </div>
                          
                        <p class="body-dark" style="text-align: left">
                            Our flagship camera that set the standard for underwater video streaming. Like all of our cameras, Octopus offers plug-and-play installation and easy viewing for scientists, the public or a specific audience.
                        </p>
                    </div>
                </div>
                <div class="smart-wrapper" style="max-width: 800px; margin: auto; margin-top: 100px;">
                    <div class="smart-left" style="text-align: center;">
                        <img src="@/assets/images/fathom_orca.png" width="300">
                    </div>
                    <div class="smart-right">
                        <div style="text-align: center; margin-bottom: 20px;">
                            <img src="@/assets/images/Orca.png" >
                            <p class="h4">orca</p>
                        </div>
                        <p class="body-dark" style="text-align: left">
                            Powered by an NVIDIA GPU, a world leader in AI, our Orca system delivers cost-effective, long-term monitoring of underwater assets from a fixed viewpoint. Real-time artificial intelligence provides relevant footage on demand. 
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Contact -->
		<section>
			<div style="margin-top: 150px;"></div>
			<p class="h4" style="margin-bottom: 25px;">get in touch</p>
			<hr class="coral-short">
			<div style="margin-top: 50px;"></div>
			<p class="h2-sand" style="margin-bottom: 25px;">See Fathom in Action</p>
			<p class="body" style="max-width: 400px; margin: auto;">
				Learn how Fathom delivers modular, cost-effective underwater monitoring solutions to businesses and organizations around the world. 
			</p>
			<b-button style="margin-top: 50px;" @click="$router.push('/contact')">get in touch</b-button>
			<!-- <hr class="coral"> -->
		</section>

		<section>
			<div style="margin-top: 150px;"></div>
			<img src="@/assets/images/fathom_family.png" width=600>
		</section>


    </div>
</template>

<script>
export default {
    name: 'Conservation',
}
</script>

<style lang="scss">
@import "@/styles/_variables.scss";
@import url('https://fonts.googleapis.com/css?family=Inconsolata');

.acoustic {
    width: 100vw;
    height: 300px;
    margin-top: 70px;
    position: absolute;
    top: 0;
    left: 0;
}
.humpback {
    position: absolute;
    top: 0;
    left: 0;
}

.photo {
	width: 100vw;
}

.fade {
    background-image: linear-gradient(black, $background);
    height: 200px;
    margin-top: -10px;
}
.lines {
    position: absolute;
    right: 0px;
    height: 500px;
    margin-top: 40px;
    margin-right: 20px;
}
.lights {
    margin-top: 100px;
}
.computer-lines {
    width: 800px;
    margin-top: 100px;
}
.plot {
    margin-top: 100px;
}
.loc-tahiti {
    position: absolute;
    top: 175px;
    left: 505px;
}
.loc-hawaii {
    position: absolute;
    top: 80px;
    left: 505px;
}
.loc-text-tahiti {
	font-family: 'Inconsolata', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150%;
	text-align: center;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #373F4D;
    position: absolute;
    top: 180px;
    left: 555px;
}
.loc-text-hawaii {
	font-family: 'Inconsolata', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150%;
	text-align: center;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #373F4D;
    position: absolute;
    top: 87px;
    left: 555px;
}
.wireframe-earth {
    position: relative;
    max-width: 800px;
    margin: auto;
}

.banner {
    width: 100vw;
}

</style>