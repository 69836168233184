<template>
	<div class="wrapper title-image-about">
		<section class="section" style="padding-top: 250px;">
			<img class="coral-down" src="@/assets/images/Orca.png">
			<div class="h2">Purchasing Terms & Conditions</div>
			<img class="coral-down" src="@/assets/images/octopus.png">
			<div>
				<p class="body" style="max-width: 600px; margin: auto; margin-bottom: 60px; text-align: left" >
					Fathom Ocean stands behind the quality of all of its products and services. 
                    Please contact us by phone at +1 (720) 352-8507, at info@fathomocean.com, 
                    or using the <span style="cursor: pointer;" @click="route('Contact')">contact form</span> if you need assistance or have any questions 
                    regarding your Fathom Ocean product or service.
				</p>
			</div>
			<img src="@/assets/images/periscope.png" width="10" style="margin-bottom: 30px;">
        </section>

        <section>
            <div class="h2">Payments, Cancellations & Returns</div>
            <p class="body" style="max-width: 600px; margin: auto; text-align: left;">
                <strong style="color: white">PAYMENT POLICY</strong><br>
                For orders that are not paid in full at the outset, Fathom Ocean uses a three-phase payment schedule that aligns with manufacturing, shipping, and receipt by the customer. When a purchase order is received, Fathom will invoice the customer within 48 hours. The following payment schedule can only be modified by Fathom Ocean prior to receiving a purchase order:
                <ol type="1">
                    <li>Once 50% of the total payment has been received the build process will begin for the order.</li>
                    <li>Once 90% of the total payment has been received, the packing and shipping process will begin.</li>
                    <li>The remaining 10% of the total payment is due NET30.</li>
                </ol>
            </p>
            <p class="body" style="max-width: 600px; margin: auto; text-align: left; margin-top: 40px;">
                <strong style="color: white">CANCELLATIONS & RETURNS</strong><br>
                Most Fathom Ocean solutions are built with a specific client and site in mind. For example, cables may be cut to specific lengths, unique connectors may be used, enclosures may be instrumented for specific purposes and products may be configured for unique network environments. As such, the following cancellation and return policies apply:
                <ol type="1">
                    <li>All requests for purchase cancellations or returns must be submitted in writing, either by mail or email.</li>
                    <li>Most builds begin quickly after an order is received. However, if an order is cancelled before the build process begins, or before any specialized materials are purchased, the customer may be entitled to a full refund.</li>
                    <li>If an order is cancelled after the build process begins, or 15 days following the receipt of the initial 50% payment (whichever comes first), 10% of the entire invoice will be charged.</li>
                    <li>If an order is cancelled after the build process has completed and/or the order has shipped, 25% of the entire invoice will be charged, in addition to return shipping and insurance charges.</li>
                    <li>Special order and customized items typically cannot be returned for a refund. These items include, but are not limited to: All cabling, bulkheads and connectors that have been cut to length, molded to a cable or installed into equipment. This also includes housings, instruments, mounts, fasteners, strain relief and hold-down equipment that has been customized for a specific project. For customized items that do not meet the specified project requirements, please see our warranty policies.</li>
                    <li>Any equipment that has been installed or tested outside or under water cannot be returned without prior arrangement with Fathom Ocean.</li>
                </ol>
            </p>
        </section>

        <section>
            <div class="h2" style="margin-top: 100px;">Packing, Shipping & Insurance</div>
            <p class="body" style="max-width: 600px; margin: auto; text-align: left;">
                <strong style="color: white">SHIPPING</strong><br>
                Fathom Ocean products and solutions are built with care to ensure that they will fulfill their intended purpose. As such, we use best practices to carefully package, ship and insure all products to ensure safe and timely arrival at the final destination. Unless otherwise arranged prior to receiving an order, each shipment will conform to the following policy: <br>
                <ol type="1">
                    <li>Fathom Ocean requires that all shipments be insured by the shipping carrier or by a third party to cover the total value of the packaged items.</li>
                    <li>Shipping and insurance costs will be included in the purchase invoice with costs paid by the purchaser.</li>
                    <li>Shipments will have tracking numbers and require a signature for proof of delivery </li>
                    <li>All received shipments must be inspected promptly at time of delivery. VITB must be notified of any damage within 7 days of receipt of a shipment. </li>
                    <li>To reduce our ecological footprint and reduce costs to our clients, we often use robust, reusable shipping containers/cases. Unless otherwise arranged, these must be returned, at the client's expense, to Fathom Ocean within 14 days of receipt. We will provide estimates for the cost of return shipping at the time of invoicing, vs. the cost of purchasing the shipping container/case. </li>
                    <li>If shipments must be sent to locations other than the site of installation, it is the client’s responsibility to arrange further transport, shipping and insurance. </li>
                    <li>For personalized installation or maintenance services performed by Fathom-authorized technicians, received items must be readily accessible at the time of the technician's arrival at the site. Delays may incur additional fees. </li>
                </ol>
            </p>
        </section>

        <!-- Contact -->
		<section>
			<div style="margin-top: 50px;"></div>
			<b-button style="margin-top: 50px;" @click="$router.push('/contact')">get in touch</b-button>
			<!-- <hr class="coral"> -->
		</section>
		
	</div>
</template>

<script>

export default {
    name: 'Terms',
    methods: {
        route(id) {
            this.$router.push(`/${id}`);
        },
    },
}
</script>

<style scoped>
.wrapper {
	margin-top: -160px;
}

.title-image-about {
	background: url(../assets/images/surface_image_about.png) no-repeat;
	background-size: 100vw;
}

li {
    margin-left: 30px;
    padding-left: 10px;
}

</style>