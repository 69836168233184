<template>
    <div>
        <section>
            <div style="margin-top: 50px;"></div>
			<p class="h4" style="margin-bottom: 25px;">get in touch</p>
			<hr class="coral-short">
			<div style="margin-top: 50px;"></div>
			<p class="h2" style="margin-bottom: 25px;">See Fathom in Action</p>
			<p class="body" style="max-width: 400px; margin: auto;">
				Learn how Fathom delivers modular, cost-effective underwater monitoring solutions to businesses and organizations around the world. 
			</p>
            <div style="margin-bottom: 50px;"></div>

            <div style="position: relative">
                <img class="jelly" src="@/assets/images/Jelly.png">
            </div>
        </section>

        <section>
            <div class="form">
                <form ref="form">
                    <b-field label="Name">
                        <b-input value="" v-model="name"></b-input>
                    </b-field>

                    <b-field label="Email">
                        <b-input type="email" value="" v-model="email"></b-input>
                    </b-field>

                    <b-field label="Phone">
                        <b-input value="" v-model="phone"></b-input>
                    </b-field>

                    <b-field label="Title">
                        <b-input value="" v-model="title"></b-input>
                    </b-field>

                    <b-field label="Company/Organization">
                        <b-input value="" v-model="org"></b-input>
                    </b-field>

                    <b-field label="Sector">
                        <b-select placeholder="Select a sector" v-model="sector">
                            <option value="Industry">Industrial</option>
                            <option value="Research">Research</option>
                        </b-select>
                    </b-field>

                    <b-field label="Message">
                        <b-input type="textarea" v-model="message"></b-input>
                    </b-field>

                    <b-button style="margin-top: 50px; margin-bottom: 100px;" @click="send">Submit</b-button>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';
import{ init } from '@emailjs/browser';
init("user_oE6ffZm4i2t3L0xfQ8CXh");

export default {
    name: 'Demo',
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            title: '',
            org: '',
            sector: '',
            message: '',
        }
    },
    methods: {
        submit() {
            console.log(this.name, this.email, this.phone, this.title, this.org, this.sector)
        },
        send() {
            var templateParams = {
                name: this.name,
                email: this.email,
                phone: this.phone,
                title: this.title,
                org: this.org,
                sector: this.sector,
                message: this.message,
            };
            
            emailjs.send('fathom_website', 'template_trgn8jz', templateParams)
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    this.$buefy.toast.open({
                        message: 'Message sent!',
                        type: 'is-success'
                    });
                    this.resetForm();
                }, (error) => {
                    console.log('FAILED...', error);
                    this.$buefy.toast.open({
                        message: error,
                        type: 'is-danger'
                    });
                });
        },
        resetForm() {
            this.name = '';
            this.email = '';
            this.phone = '';
            this.title = '';
            this.org = '';
            this.sector = '';
            this.message = '';
        },
    },
}
</script>

<style lang="scss">
@import "@/styles/_variables.scss";
@import url('https://fonts.googleapis.com/css?family=Inconsolata');

.form {
    max-width: 400px; 
    margin: auto; 
    text-align: left;
    padding: 20px;
}

.label {
    font-family: brandon-grotesque, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	color: #FFFFFF;
}
.jelly {
	position: absolute;
	right: 0px;
	top: -100px;
	z-index: -1;
}

</style>