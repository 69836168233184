<template>
    <div>
        <b-navbar>
            <template #brand>
                <b-navbar-item @click="route('')">
                    <img
                        src="@/assets/images/name_logo.png"
                        alt="Fathom Ocean"
                        width="150"
                    >
                </b-navbar-item>
            </template>
            <template #start>
            </template>
            <template #end>
                <b-navbar-item :class="{ active: currentPage === 'industry' }">
                    <a @click="route('industry')">industry</a>
                </b-navbar-item>
                <b-navbar-item :class="{ active: currentPage === 'conservation' }">
                    <a @click="route('conservation')">conservation</a>
                </b-navbar-item>
                <b-navbar-item :class="{ active: currentPage === 'about' }">
                    <a @click="route('about')">about</a>
                </b-navbar-item>
                <b-navbar-item>
                    <b-button @click="route('contact')">get in touch</b-button>
                </b-navbar-item>
            </template>
        </b-navbar>
    </div>
</template>

<script>

export default {
    name: 'Navbar',
    data() {
        return {
            currentPage: 'home',
        }
    },
    methods: {
        route(id) {
            this.currentPage = id;
            this.$router.push(`/${id}`);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.navbar {
    font-family: brandon-grotesque, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    align-items: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0);
}
.button {
	background-color: $coral;
	border-radius: 5px;
	border: 0;
	color: white;
	font-weight: bold;
    text-transform: uppercase;
    padding: 3px 14px;
}
.navbar-item a {
    color: white;
}
.navbar-item:hover a {
    color: $coral;
}
.active a {
    color: $coral;
}

</style>