<template>
  <div>
	<div style="padding: 40px;">
		<Navbar/>
	</div>

	<!-- <Main/> -->
	<router-view/>
	<footer class="footer">
		<div class="content" style="text-align: left; color: white; padding: 10px;">
			<img src="@/assets/images/logo.png" width="25" style="margin-left: 12px">
			<div class="row">
				<div class="column">
					<p class="h3">Fathom Ocean</p>
					<p class="body3">
						6655 Arapahoe Road, Unit B, <br>
						Boulder, CO, 80303 <br>
						United States <br>
						1-720-352-8507 <br>
						info@fathomocean.com
					</p>
				</div>
				<div class="column">
					<p class="h3">About</p>
					<p class="body3">
						<span style="cursor: pointer" @click="route('About')">Our Team </span><br>
						FAQ
					</p>
				</div>
				<div class="column">
					<p class="h3">Support</p>
					<p class="body3">
						<span style="cursor: pointer" @click="route('Contact')">Customer Support </span><br>
						<span style="cursor: pointer" @click="route('Terms')">Purchasing Terms</span><br>
						<span style="cursor: pointer" @click="route('Warranty')">Warranty & Repair</span>
					</p>
				</div>
			</div>
		</div>
	</footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Main from '@/components/Main.vue'


export default {
	name: 'Home',
	components: {
		Navbar,
		Main,
  	},
	methods: {
		route(id) {
			this.currentPage = id;
			this.$router.push(`/${id}`);
		},
	},
}
</script>

<style lang="scss">
@import "@/styles/_variables.scss";

.h1 {
	font-size: 60px;
	font-family: brandon-grotesque, sans-serif;
	font-weight: normal;
	font-style: normal;
	color: $pale-blue;
	line-height: 115%;
	margin-bottom: 75px;
	letter-spacing: 1px;
}

.h2 {
	font-size: 50px;
	font-family: brandon-grotesque, sans-serif;
	font-weight: normal;
	font-style: normal;
	color: $pale-blue;
	line-height: 115%;
	margin-bottom: 75px;
	padding: 10px;
}

.h2-sand {
	font-size: 50px;
	font-family: brandon-grotesque, sans-serif;
	font-weight: normal;
	font-style: normal;
	color: $sand;
	line-height: 115%;
	margin-bottom: 75px;
}
.h2-dark {
	font-size: 50px;
	font-family: brandon-grotesque, sans-serif;
	font-weight: normal;
	font-style: normal;
	color: $background;
	line-height: 115%;
	margin-bottom: 75px;
}

.h3 {
	font-family: brandon-grotesque, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 115%;
	color: $pale-blue;
}

.h4 {
	color: $coral;
	font-family: 'Inconsolata', sans-serif;
	font-size: 20px;
	line-height: 125%;
	text-align: center;
	letter-spacing: 4px;
	text-transform: uppercase;
}
.h4-blue {
	color: $pale-blue;
	font-family: 'Inconsolata', sans-serif;
	font-size: 20px;
	line-height: 125%;
	text-align: center;
	letter-spacing: 4px;
	text-transform: uppercase;
}

.h4-dark {
	color: $background;
	font-family: 'Inconsolata', sans-serif;
	font-size: 20px;
	line-height: 125%;
	text-align: center;
	letter-spacing: 4px;
	text-transform: uppercase;
}
.h5 {
	color: $pale-blue;
	font-family: 'Inconsolata', sans-serif;
	font-size: 35px;
	line-height: 125%;
	text-align: center;
	letter-spacing: 4px;
	text-transform: uppercase;
	padding: 10px;
}

.body {
	font-family: brandon-grotesque, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	text-align: center;
	color: #FFFFFF;
}
.body-dark {
	font-family: brandon-grotesque, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	text-align: center;
	color: $background;
}

.body2 {
	font-family: 'Inconsolata', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	text-align: center;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #FFFFFF;
}

.body2-left {
	font-family: 'Inconsolata', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	text-align: left;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #FFFFFF;
}

.body3 {
	font-family: brandon-grotesque, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	color: #FFFFFF;
}

.fathom-box {
	background-color: $background;
	width: fit-content;
	margin: auto;
	padding: 10px;
	border-radius: 10px;
	margin-top: 40px;
}
.fathom-box-coral {
	background-color: $coral;
	width: fit-content;
	margin: auto;
	padding: 10px;
	border-radius: 10px;
	margin-top: 40px;
}

.footer {
	background-color: $background !important;
}
.fa {
	color: black;
}

.column {
  float: left;
  width: 33.33%;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.button {
	background-color: $coral !important;
	border-radius: 5px;
	border: 0 !important;
	color: white !important;
	font-weight: normal;
	text-transform: uppercase;
	font-family: brandon-grotesque, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
}



</style>
