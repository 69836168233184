<template>
	<div class="wrapper title-image-about">
		<section class="section" style="padding-top: 250px;">
			<img class="coral-down" src="@/assets/images/Orca.png">
			<div class="h2">Warranty & Repair Policies</div>
			<img class="coral-down" src="@/assets/images/octopus.png">
			<div>
				<p class="body" style="max-width: 600px; margin: auto; margin-bottom: 60px; text-align: left" >
                    Fathom Ocean products and systems are designed to function for long durations in 
                    aquatic environments. We offer a limited warranty on most products, components 
                    and services, as detailed below, if, and only if, products and systems are 
                    installed following our specifications. We recommend that our clients explore 
                    options for insuring their products and systems against occurrences that fall 
                    outside of the scope of our warranties.<br>

                    <br>
                    To lower our ecological footprint, we strive to make repairable and upgradeable 
                    products whenever possible. However, to maintain the limited warranty on your 
                    products, repairs and upgrades must be completed by or in coordination 
                    with Fathom Ocean.  <br>    
					
                    <br>
                    Fathom Ocean stands behind the quality of all of its products and services. 
                    Please contact us by phone at +1 (720) 352-8507, at info@fathomocean.com, 
                    or using the <span style="cursor: pointer;" @click="route('Contact')">contact form</span> if you need assistance or have any questions 
                    regarding your Fathom Ocean product or service.
				</p>
			</div>
			<img src="@/assets/images/periscope.png" width="10" style="margin-bottom: 30px;">
        </section>

        <section>
            <div class="h2">Warranty Policies</div>
            <p class="body" style="max-width: 600px; margin: auto; text-align: left;">
                <strong style="color: white">GENERAL EXCLUSIONS</strong><br>
                NO WARRANTY OF ANY KIND WILL BE OFFERED IF ANY PART OF A FATHOM OCEAN SYSTEM OR PRODUCT IS SERVICED OR ALTERED BY AN UNAUTHORIZED PERSON WITHOUT PRIOR WRITTEN APPROVAL FROM FATHOM OCEAN, WITH THE EXCEPTION OF USER-SERVICEABLE WIPER UNITS, IF SERVICED AND/OR REPLACED ACCORDING TO FATHOM OCEAN USER GUIDELINES.
            </p>
            <p class="body" style="max-width: 600px; margin: auto; text-align: left; margin-top: 40px;">
                <strong style="color: white">FATHOM OCEAN LIMITED WARRANTY COVERAGE</strong><br>
                A Fathom Ocean limited warranty applies only to the following components and only for the specified duration:<br>
                <br>
                One year from date of purchase:   
                <ul type="1">
                    <li>Network Cameras</li>
                    <li>Vision system submersible housing and glass viewports & domes</li>
                    <li>Internal system parts</li>
                    <li>Illuminator housings</li>
                    <li>Electronics</li>
                    <li>External mounting and hardware/ties</li>
                    <li>Network and powering systems</li>
                    <li>Cabling and bulkheads/wet-mate connectors</li>
                    <li>Solar panels</li>
                    <li>Installation or maintenance completed by Fathom authorized/approved field technicians</li>
                </ul>
                <br>
                Duration per manufacturer's warranty:  
                <ul type="1">
                    <li>Buoys</li>
                </ul>
            </p>

            <p class="body" style="max-width: 600px; margin: auto; text-align: left; margin-top: 40px;">
                <strong style="color: white">LIMITED WARRANTY LIMITATIONS</strong><br>
                Fathom Ocean limited warranties DO NOT cover:<br>  
                <ul type="1">
                    <li>Damage resulting from modification or alteration to hardware or software.</li>
                    <li>Tampering with any components of the Fathom system.</li>
                    <li>Unsuitable operating environment beyond product specifications.</li>
                    <li>Improper or deferred maintenance or negligence.</li>
                    <li>Damage caused by an accident, power surge, natural disaster or force majeure such as fire, wind, earthquake, lightning, hurricanes, storms, vandalism, computer viruses or other computer malfunctions unrelated to Fathom Ocean products or services.</li>
                    <li>Products of which product/serial number identification labels have been removed or altered.</li>
                    <li>Any submersible housing that has been opened (except for initial vacuum seal process before initial deployment, for applicable products) without prior authorization in writing from Fathom Ocean.</li>
                    <li>Loss of data–you must regularly back up the data stored on your product to a separate storage product.</li>
                    <li>Normal, expected wear and tear on exposed surfaces and mechanical parts.</li>
                    <li>Corrosion of wet-mate connectors following improper mating, i.e., without correct application of specified mating grease prior to each mating.</li>
                    <li>Corrosion of exposed metals if systems or components are improperly mounted, or not isolated or galvanically protected per Fathom Ocean specifications.</li>
                    <li>Installation, disassembly or maintenance while a system or component is energized.</li>
                </ul>
            </p>

            <p class="body" style="max-width: 600px; margin: auto; text-align: left; margin-top: 40px;">
                <strong style="color: white">PERFORMANCE GUARANTEE</strong><br>
                There is no guarantee of uninterrupted or error-free operation, live-streaming connectivity, network integrity or internet service provision (ISP). There is no guarantee of imaging quality or experience, which may be impacted by natural water conditions or natural lighting conditions for which Fathom systems cannot adjust.<br>  
                <br>
                FATHOM OCEAN DOES NOT PROVIDE ANY OTHER GUARANTIES OR WARRANTIES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF IMPLIED WARRANTIES, SO THIS LIMITATION MAY NOT APPLY TO YOU.<br>
                <br>
                Fathom Ocean is not responsible for returning to you any received product not covered by this limited warranty.
            </p>
        </section>

        <section>
            <div class="h2" style="margin-top: 100px;">Repair Policies</div>
            <p class="body" style="max-width: 600px; margin: auto; text-align: left;">
                <strong style="color: white">AUTHORIZED SERVICE PROVIDERS</strong><br>
                We strongly recommend that all regular service and repairs and modifications to our systems be performed or overseen by View Into The Blue®-authorized technicians.<br>
                <br>
                REPAIR, SERVICE OR ALTERATION OF FATHOM OCEAN PRODUCTS, COMPONENTS OR SYSTEMS PERFORMED BY NON-AUTHORIZED PROVIDERS WILL VOID THE WARRANTY.<br>
                <br>
                We’re eager to help you get your product or system back in service as soon as possible. Please contact us to arrange for an evaluation. Please do not send products to View Into The Blue® without first receiving a return authorization.<br>
                <br>
            </p>

            <p class="body" style="max-width: 600px; margin: auto; text-align: left;">
                <strong style="color: white">REPAIRABILITY AND SERVICEABILITY</strong><br>
                To provide the best value to our customers and to limit our ecological footprint, we aim to design our systems and components so that they can be repaired, refurbished, upgraded or expanded over the duration of their normal expected lifetimes. With only scheduled maintenance and occasional component replacement or upgrades, many of our aquatic observatory systems have performed admirably in the field for over a decade.<br>
            </p>

            <br>
             <p class="body" style="max-width: 600px; margin: auto; text-align: left;">
                <strong style="color: white">REPAIRABILITY AND SERVICEABILITY SCHEDULE</strong><br>
                We follow the following repair and support policies for systems and components that fall outside of our standard limited warranty coverages and durations:<br>
                <br>
                Full Service Period - 0 to 3 years from the date of purchase.
                <ul type="1">
                    <li>It is expected that all systems and components can be replaced, refurbished, repaired, remanufactured or upgraded/expanded during this period.</li>
                    <li>Limitations in the availability of components, materials and supplies are beyond our control and may limit these efforts, or require substitutions or modifications.</li>
                    <li>Internal system parts</li>
                </ul>
                <br>
                Best Effort Service Period - 3 to 5 years from the date of purchase.
                <ul type="1">
                    <li>Changes in technology, standards and tooling may limit our efforts to replace, refurbish, repair, remanufacture and upgrade/expand some systems and components.</li>
                    <li>We will make our best effort, though associated support costs are likely to be higher.</li>
                </ul>
                <br>
                Limited Service Period - 5 to 7 years from the date of purchase.
                <ul type="1">
                    <li>Some systems and components may be obsolete and therefore impractical or impossible to replace, refurbish, repair, remanufacture or upgrade/expand.</li>
                    <li>Costs of service and repairs performed during this period are likely to be high.</li>
                </ul>
                <br>
                Minimal Service Period - beyond 7 years from the date of purchase.
                <ul type="1">
                    <li>Many systems and components may be obsolete and therefore impossible to replace, refurbish, repair, remanufacture or upgrade/expand.</li>
                    <li>In addition, many systems and components may have reached the end of their expected lifetime.</li>
                </ul>
            </p>
        </section>

        <!-- Contact -->
		<section>
			<div style="margin-top: 50px;"></div>
			<b-button style="margin-top: 50px;" @click="$router.push('/contact')">get in touch</b-button>
			<!-- <hr class="coral"> -->
		</section>
		
	</div>
</template>

<script>

export default {
    name: 'Warranty',
    methods: {
        route(id) {
            this.$router.push(`/${id}`);
        },
    },
}
</script>

<style scoped>
.wrapper {
	margin-top: -160px;
}

.title-image-about {
	background: url(../assets/images/surface_image_about.png) no-repeat;
	background-size: 100vw;
}

ul {
  list-style-type: circle;
}

li {
    margin-left: 30px;
    padding-left: 10px;
}

</style>